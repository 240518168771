/**
 * @method GET
 * @description получаем info block
 * */

export default async function apiContentInfoBlock (slug: string) {
  const endpoint = `/content/v1/seo/page/${slug}/infoblock`

  return await useAsyncQuery(async ({ axios, path }) => {
    return await axios.get(path + endpoint)
  })
}
