<template>
  <div class="ui-info-block" data-testid="element_000158">
    <section class="ui-info-block__text" data-testid="element_000160" v-html="text" />
  </div>
</template>

<script lang="ts" setup>
import apiContentInfoBlock from '~/api/content/info-block'

const props = defineProps({
  entity: {
    type: String,
    required: true,
  },
  content: {
    type: String,
    required: false,
  },
})

const seo = useSeoModule()

const infoBlockQuery = useAsyncData('info-block', async () => {
  return await apiContentInfoBlock(props.entity)
}, { dedupe: 'defer' })

const unpackListMiddleware = (response: unknown) => {
  return response?.data?.value?.data?.item || { infoblock: '', slug: '' }
}

const text = computed(() => prepareSeoTemplateByVariable(props.content ? props.content : unpackListMiddleware(infoBlockQuery).infoblock, seo.flatContext.value).text)
</script>

<style lang="scss">
.ui-info-block {
  color: map_get($grayPalette, "color_ff");
  line-height: normal;

  &__text {
    h2 {
      margin: 0 0 12rem;
      color: map_get($grayPalette, "color_ff");
      font-size: 18rem;
      font-weight: 600;
      line-height: 27rem;

      @include bp-tabletXS('min') {
        font-size: 22rem;
        line-height: 33rem
      }
    }

    h3 {
      margin: 0 0 12rem;
      color: map_get($grayPalette, "color_ff");
      font-size: 16rem;
      font-weight: 600;
      line-height: 24rem;

      @include bp-tabletXS('min') {
        font-size: 20rem;
        line-height: 30rem;
      }
    }

    p {
      margin: 0 0 24rem;
      font-size: 14rem;
      font-style: normal;
      font-weight: 400;
      line-height: 22rem;
      letter-spacing: 0.28rem;

      @include bp-tabletXS('min') {
        letter-spacing: unset;
        font-size: 16rem;
        line-height: 24rem;
      }

      &:last-child {
        margin: 0;
      }
    }
    a {
      color: map-get($colorPalette, "color_09");
    }
  }
}
</style>
